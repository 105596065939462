import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function postBoostGoogleLocation(id) {
  try {
    const response = await axios.post(`/google_locations/${id}/boost`, {}, { headers: { Accept: 'application/json' } });
    return response;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function postIgnoreGoogleLocation(id) {
  try {
    const response = await axios.post(`/google_locations/${id}/hide`, {}, { headers: { Accept: 'application/json' } });
    return response;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
